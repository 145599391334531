import * as orderTypes from "./../ActionTypes";

export interface prepaidLimitDataType {
  flag: number;
  monthly_limit: number;
  prepaid_amount: number;
  transaction_limit: number;
  monthly_orders_count: number;
  monthly_orders_limit: number;
}

const initialState = {
  ordersData: null,
  pendingOrders: {
    data: [],
    page: 1,
    isNext: false,
  },
  completedOrders: {
    data: [],
    page: 1,
    isNext: false,
  },
  dailyReports: {},
  weeklyReports: {},
  orderDetails: {
    show: false,
    orderHash: "",
    data: {},
    itemsPayload: [],
    reviewDetails: {},
    transactionId: "",
  },
  showPrepaidDeliveryDateComponent: false,
  tempOrderDetailsForSendBill: {},
  transactionDetails: {},
  orderDetailsOptionsMenu: [],
  billItemInfo: {
    orderId: "",
    customerName: "",
    customerPhone: "",
  },
  transactionList: {
    settled_amount: "",
    transaction_list: [],
    unsettled_amount: "",
    is_next_page: false,
  },
  bannerDetails: {},
  orderPromoDetails: {},
  deliveryPartnerDetails: {},
  tempOrderPaymentData: null,
  transactionDetailsChildOrderForPartialPayment: {},
  prepaidLimitData: {} as prepaidLimitDataType,
};

const OrdersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case orderTypes.SAVE_ORDERS: {
      return {
        ...state,
        ordersData: action.data,
      };
    }
    case orderTypes.SAVE_PENDING_ORDERS: {
      const tempOrderData = state.pendingOrders;
      return {
        ...state,
        pendingOrders: {
          data: [...tempOrderData.data, ...action.data],
          isNext: action.isNext,
          page: action.page,
        },
      };
    }
    case orderTypes.SAVE_COMPLETED_ORDERS: {
      const tempOrderData = state.completedOrders;
      return {
        ...state,
        completedOrders: {
          data: [...tempOrderData.data, ...action.data],
          isNext: action.isNext,
          page: action.page,
        },
      };
    }
    case orderTypes.EMPTY_PENDING_ORDERS:
      return { ...state, pendingOrders: { ...state.pendingOrders, data: action.data } };
    case orderTypes.SAVE_DAILY_REPORTS:
      return { ...state, dailyReports: action.data };
    case orderTypes.SAVE_WEEKLY_REPORTS:
      return { ...state, weeklyReports: action.data };
    case orderTypes.TOGGLE_ORDER_DETAILS_MODAL:
      return {
        ...state,
        orderDetails: { ...state.orderDetails, orderHash: action.data.orderHash, show: action.data.show },
      };
    case orderTypes.SAVE_ORDER_DETAILS_BY_ORDER_ID:
      return { ...state, orderDetails: { ...state.orderDetails, data: action.data } };
    case orderTypes.SAVE_ORDER_REVIEW_DETAILS:
      return { ...state, orderDetails: { ...state.orderDetails, reviewDetails: action.data } };
    case orderTypes.EMPTY_COMPLETED_ORDERS_LIST:
      return { ...state, completedOrders: { ...state.completedOrders, data: action.data } };
    case orderTypes.SET_ORDER_ITEMS_PAYLOAD:
      return { ...state, orderDetails: { ...state.orderDetails, itemsPayload: [...action.data] } };
    case orderTypes.MARK_PREPAID_DELIVERY_DATE:
      return { ...state, showPrepaidDeliveryDateComponent: action.data };
    case orderTypes.SET_ORDER_DETAILS_DATA:
      return { ...state, tempOrderDetailsForSendBill: action.data };
    case orderTypes.SAVE_TRANSACTION_DATA_FOR_ORDER:
      return { ...state, transactionDetails: action.data };
    case orderTypes.SET_ORDER_DETAILS_OPTIONS_MENU:
      return { ...state, orderDetailsOptionsMenu: action.data };
    case orderTypes.SAVE_ORDER_INFO:
      return {
        ...state,
        billItemInfo: {
          orderId: action.data.orderId,
          customerName: action.data.customerName,
          customerPhone: action.data.customerPhone,
        },
      };
    case orderTypes.SAVE_TRANSACTION_LIST:
      return {
        ...state,
        transactionList: {
          settled_amount: action.data.settled_amount,
          transaction_list: action.data.transaction_list,
          unsettled_amount: action.data.unsettled_amount,
          is_next_page: action.data.is_next_page,
        },
      };
    case orderTypes.SAVE_ORDER_BANNER_DETAILS:
      return { ...state, bannerDetails: action.data };
    case orderTypes.SAVE_ORDER_PROMO_CODE_DETAILS:
      return { ...state, orderPromoDetails: action.data };
    case orderTypes.SAVE_DELIVERY_PARTNER_DETAILS:
      return { ...state, deliveryPartnerDetails: action.data };
    case orderTypes.SET_ORDER_PAYMENT_TEMP_DATA:
      return { ...state, tempOrderPaymentData: action.data };
    case orderTypes.SAVE_PARTIAL_PAYMENT_TRANSACTION_DATA_FOR_CHILD_ORDER:
      return { ...state, transactionDetailsChildOrderForPartialPayment: action.data };
    case orderTypes.SAVE_PREPAID_LIMIT_DATA:
      return { ...state, prepaidLimitData: action.data };
    default:
      return state;
  }
};

export default OrdersReducer;
