import { useEffect, useMemo, useState } from "react";
import "./HelpSection.scss";
import helpSectionIcon from "../../Assets/images/helpSectionIcon.svg";
import useIsDashy from "../../Utils/useIsDashy";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../Redux/Reducers";
import { getHelpSection } from "../../Redux/Actions/helpSection";

export const REDIRECTION_CTA_TYPE = {
  DEFAULT: 1,
  BLUE_LINK: 2,
};

function HelpSection({ redirectionCtaType = REDIRECTION_CTA_TYPE.DEFAULT }) {
  const isDashy = useIsDashy();
  const dispatch = useDispatch();

  const location = useLocation();

  const [pathName, setPathName] = useState("");

  const { helpSectionStatus } = useSelector((state: RootState) => ({
    helpSectionStatus: state.helpReducer.helpSectionStatus,
  }));

  useEffect(() => {
    dispatch(getHelpSection());
  }, []);

  useEffect(() => {
    // Check if the pathname ends with a slash
    const updatedPathName = location.pathname.endsWith("/")
      ? location.pathname.slice(0, -1) // Remove trailing slash
      : location.pathname;

    setPathName(updatedPathName);
  }, [location.pathname]);

  // todo remove this later after setting redirection links for prod
  // todo after validating this, us is_visible that we are getting from be

  function handleHelpClick() {
    window.open(helpSectionStatus?.[pathName.trim()]?.route, "_blank");
  }

  const ctaContent = () => {
    switch (redirectionCtaType) {
      case REDIRECTION_CTA_TYPE.BLUE_LINK:
        return <span className="tw-cursor-pointer tw-text-base tw-font-semibold tw-text-[#2B74D8]">Help</span>;
      default:
        return (
          <>
            <img width={16} height={16} src={helpSectionIcon} />
            {isDashy && <span className="helpText">Help</span>}
          </>
        );
    }
  };

  return (
    <>
      {helpSectionStatus?.[pathName.trim()]?.is_visible === true && 
        <div className="HelpSection" onClick={handleHelpClick} style={!isDashy ? { marginRight: "16px" } : {}}>
          {ctaContent()}
        </div>
      }
    </>
  );
}

export default HelpSection;
