export const SAVE_SUBSCRIPTION_DETAILS = "USER/SAVE_SUBSCRIPTION_DETAILS";
export const SAVE_USER_GMAIL_PAGE_INFO = "SAVE_USER_GMAIL_PAGE_INFO";
export const GET_DELIVERY_SETUP_INFO = "USER/GET_DELIVERY_SETUP_INFO";
export const SAVE_DELIVERY_SETUP_INFO = "USER/SAVE_DELIVERY_SETUP_INFO";
export const SET_OR_REMOVE_DEFAULT_DELIVERY_PARTNER = "USER/SET_OR_REMOVE_DEFAULT_DELIVERY_PARTNER";
export const GET_DELIVERY_PARTNER_INPUT_CONFIG = "USER/GET_DELIVERY_PARTNER_INPUT_CONFIG";
export const ADD_DELIVERY_PARTNER = "USER/ADD_DELIVERY_PARTNER";
export const FETCH_CITY_AND_STATE_FROM_PINCODE = "USER/FETCH_CITY_AND_STATE_FROM_PINCODE";
export const GET_STORE_PICKUP_ADDRESSES = "USER/GET_STORE_PICKUP_ADDRESSES";
export const ADD_OR_UPDATE_STORE_PICKUP_ADDRESS = "USER/ADD_OR_UPDATE_STORE_PICKUP_ADDRESS";
export const DELETE_STORE_PICKUP_ADDRESS = "USER/DELETE_STORE_PICKUP_ADDRESS";
export const GET_SERVICEABILITY_BY_PINCODES = "USER/GET_SERVICEABILITY_BY_PINCODES";
