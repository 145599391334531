import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./Redux/Store";

let env = "production";
if (typeof window !== "undefined") {
  env = window.location.href.includes("localhost") ? "dev" : "production";
}

if (typeof process !== "undefined" && process.env) {
  env = process.env.REACT_APP_CUSTOM_ENV?.toLowerCase() || env;
}

ReactDOM.render(
  // @ts-ignore
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
