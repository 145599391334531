import { lazy, Suspense } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { AppWebView } from "./AppWebview";
import { withAppBar } from "./Components/Webview/AppBar";
import { PageLoader } from "./Components";
import { getSettingsTextPrefixByStoreType } from "./Utils/getSettingsTextPrefixByStoreType";
import {
  GOOGLE_SHOPPING_V2_DASHBOARD,
  GOOGLE_SHOPPING_V2_LANDING_PAGE,
  GOOGLE_SHOPPING_V2_MERCHANT_FORM,
  GOOGLE_SHOPPING_V2_SUCCESS_PAGE,
  RETURN_REFUND_POLICY_ROUTE,
  SETUP_B2B_ROUTE,
  SUBSCRIPTION_PAYMENT_USING_COUPONS_ROUTE,
  SUBMIT_SUGGESTION_ROUTE,
  ORDER_CANCELLATION_REFUND_ROUTE,
  COD_CHARGES_ROUTE,
  PREMIUM_PAGE,
  SUBSCRIPTION_STATUS_DETAILS,
  BRAND_WEBSITE_BUSINESS_CATEGORIES,
  BRAND_WEBSITE_CATEGORY_THEMES,
  BRAND_WEBSITE_THEME_PREVIEW,
  BRAND_WEBSITE_THEME_DEMO,
  EXIT_GATING_HOMEPAGE,
  EXIT_GATING_EDITOR,
  MY_INVOICES,
  TITAN_DASHBOARD,
  APP_UTILITIES,
  REDIRECTION_CONNECTOR,
  MARKETING_COUPONS_LIST,
  DELETE_STORE,
} from "./Constants/routesConstant";

const GOOGLE_SHOPPING_TITLE = { title: "Google Shopping" };
const InventoryCatalogList = lazy(() => import("./Components/InventoryCatalogList"));
const WhatsappMarketingLanding = lazy(() =>
  import("./Containers/WhatsappMarketingLanding").then((module) => ({ default: module.WhatsappMarketingLanding })),
);
const SocialMediaProfiles = lazy(() => import("./Containers/SocialMediaProfiles"));
const B2B = lazy(() => import("./Containers/B2B").then((module) => ({ default: module.B2B })));
const Subscription = lazy(() => import("./Containers/Subscription"));
const BrandWebsiteCategories = lazy(() => import("./Containers/BrandWebsiteCategories"));
const BrandWebsiteCategoryThemes = lazy(() => import("./Containers/BrandWebsiteCategoryThemes"));
const BrandWebsiteThemePreview = lazy(() => import("./Containers/BrandWebsiteThemePreview"));
const BrandWebsiteDemo = lazy(() => import("./Containers/BrandWebsiteDemo"));
const SubscriptionPurchaseUsingCoupon = lazy(() => import("./Containers/SubscriptionPurchaseUsingCoupon"));
const GshopV2LandingPage = lazy(
  () => import("./Containers/GoogleShoppingV2/Containers/GshopLandingPage/GshopLandingPage"),
);
const GshopMerchantFormV2 = lazy(() => import("./Containers/GoogleShoppingV2/Containers/GshopFormPage/GshopFormPage"));
const GshopSuccessPageV2 = lazy(
  () => import("./Containers/GoogleShoppingV2/Containers/GshopSuccessSetup/GshopSuccessSetup"),
);
const GshopDashboard = lazy(() => import("./Containers/GoogleShoppingV2/Containers/GshopDashboard/GshopDashboard"));
const SubmitSuggestion = lazy(() => import("./Containers/SubmitSuggestion"));

const ReturnRefundPolicyPage = lazy(() => import("./Containers/ReturnRefundPolicyPage"));
const OrderCancellationRefund = lazy(() => import("./Containers/OrderCancellationRefund"));
const ExtraAmountForCOD = lazy(() => import("./Containers/CODChargeModal"));
const CustomersLoginSettings = lazy(() => import("./Containers/CustomersLoginSettings"));
const PremiumPage = lazy(() => import("./Containers/NewPremiumPage/Containers/PremiumHomePage"));
const SubscriptionStatusDetails = lazy(
  () => import("./Containers/NewPremiumPage/Containers/SubscriptionStatusDetails"),
);
const ExitGatingHomePage = lazy(() => import("./Containers/ExitGatingHomePage"));
const ExitGatingEditorPage = lazy(() => import("./Containers/ExitGatingEditorPage"));
const MyInvoicesPage = lazy(() => import("./Containers/MyInvoices"));

const InventoryWithAppBar = withAppBar(InventoryCatalogList, { title: "Product Catalog" });
const SocialMediaProfilesWithAppBar = withAppBar(SocialMediaProfiles, { title: "Social Media" });
const GshopV2LandingPageWithAppBar = withAppBar(GshopV2LandingPage, GOOGLE_SHOPPING_TITLE);
const GshopMerchantFormV2WithAppBar = withAppBar(GshopMerchantFormV2, GOOGLE_SHOPPING_TITLE);
const GshopSuccessPageV2WithAppBar = withAppBar(GshopSuccessPageV2, GOOGLE_SHOPPING_TITLE);
const GshopDashboardWithAppBar = withAppBar(GshopDashboard, GOOGLE_SHOPPING_TITLE);
const ReturnRefundPolicyPageWithAppBar = withAppBar(ReturnRefundPolicyPage, { title: "Return and Refund Policy" });
const OrderCancellationPageWithAppBar = withAppBar(OrderCancellationRefund, {
  title: `${getSettingsTextPrefixByStoreType()} Cancellation`,
});
const ExtraAmountForCODWithAppBar = withAppBar(ExtraAmountForCOD, { title: `Cash on delivery charges` });
const SubscriptionPurchaseUsingCouponWithAppBar = SubscriptionPurchaseUsingCoupon;
const SubmitSuggestionWithAppBar = withAppBar(SubmitSuggestion, { title: "Give a Suggestion" });
const WhatsappMarketingLandingBar = withAppBar(WhatsappMarketingLanding, { title: "Whatsapp Marketing" });
const ManageDNSSettings = lazy(() => import("./Containers/ManageDNS"));
const CustomersLoginSettingsWithAppBar = withAppBar(CustomersLoginSettings, { title: "Customer Login Settings" });
const MyInvoices = withAppBar(MyInvoicesPage, { title: "My Invoices" });
const TitanDashboard = lazy(() => import("./Containers/TitanEmailDashboard"));
const TitanDashboardWithAppBar = withAppBar(TitanDashboard, { title: "Titan Email" });
const RedirectionConnector = lazy(() => import("./Containers/RedirectionConnector"));
const CouponsVouchers = lazy(() => import("./Containers/CouponsVouchers"));
const DeleteStore = lazy(() => import("./Containers/DeleteStore"));

const WebViewRouter = (): JSX.Element => {
  const { path } = useRouteMatch();
  return (
    <AppWebView>
      <Suspense fallback={<PageLoader show={true} />}>
        <Switch>
          <Route exact path={`${path}/cataloglist`} component={InventoryWithAppBar} />
          <Route exact path={`${path}/whatsapp-business-marketing`} component={WhatsappMarketingLandingBar} />
          <Route exact path={`${path}/settings/add-social-media`} component={SocialMediaProfilesWithAppBar} />
          <Route
            exact
            path={`${path}/settings/customers-login-settings`}
            component={CustomersLoginSettingsWithAppBar}
          />
          <Route exact path={`${path}${SETUP_B2B_ROUTE}`} component={B2B} />
          <Route exact path={`${path}${MARKETING_COUPONS_LIST}`} component={CouponsVouchers} />
          <Route exact path={`${path}${GOOGLE_SHOPPING_V2_LANDING_PAGE}`} component={GshopV2LandingPageWithAppBar} />
          <Route exact path={`${path}${GOOGLE_SHOPPING_V2_MERCHANT_FORM}`} component={GshopMerchantFormV2WithAppBar} />
          <Route exact path={`${path}${GOOGLE_SHOPPING_V2_SUCCESS_PAGE}`} component={GshopSuccessPageV2WithAppBar} />
          <Route exact path={`${path}${GOOGLE_SHOPPING_V2_DASHBOARD}`} component={GshopDashboardWithAppBar} />
          <Route exact path={`${path}${RETURN_REFUND_POLICY_ROUTE}`} component={ReturnRefundPolicyPageWithAppBar} />
          <Route
            exact
            path={`${path}${SUBSCRIPTION_PAYMENT_USING_COUPONS_ROUTE}`}
            component={SubscriptionPurchaseUsingCouponWithAppBar}
          />
          <Route exact path={`${path}${SUBMIT_SUGGESTION_ROUTE}`} component={SubmitSuggestionWithAppBar} />
          <Route exact path={`${path}${ORDER_CANCELLATION_REFUND_ROUTE}`} component={OrderCancellationPageWithAppBar} />
          <Route exact path={`${path}${COD_CHARGES_ROUTE}`} component={ExtraAmountForCODWithAppBar} />
          <Route exact path={`${path}${PREMIUM_PAGE}`} component={PremiumPage} />
          <Route exact path={`${path}${SUBSCRIPTION_STATUS_DETAILS}`} component={SubscriptionStatusDetails} />
          <Route exact path={`${path}/manage-dns`} component={ManageDNSSettings} />
          <Route exact path={`${path}/subscription`} component={Subscription} />
          <Route exact path={`${path}${BRAND_WEBSITE_BUSINESS_CATEGORIES}`} component={BrandWebsiteCategories} />
          <Route exact path={`${path}${BRAND_WEBSITE_CATEGORY_THEMES}/:id`} component={BrandWebsiteCategoryThemes} />
          <Route exact path={`${path}${BRAND_WEBSITE_THEME_PREVIEW}/:id`} component={BrandWebsiteThemePreview} />
          <Route exact path={`${path}${BRAND_WEBSITE_THEME_DEMO}/:id`} component={BrandWebsiteDemo} />
          <Route exact path={`${path}${EXIT_GATING_HOMEPAGE}`} component={ExitGatingHomePage} />
          <Route exact path={`${path}${EXIT_GATING_EDITOR}`} component={ExitGatingEditorPage} />
          <Route exact path={`${path}${MY_INVOICES}`} component={MyInvoices} />
          <Route exact path={`${path}${TITAN_DASHBOARD}`} component={TitanDashboardWithAppBar} />
          <Route exact path={`${path}${REDIRECTION_CONNECTOR}`} component={RedirectionConnector} />
          <Route exact path={`${path}${DELETE_STORE}`} component={DeleteStore} />
        </Switch>
      </Suspense>
    </AppWebView>
  );
};
export default WebViewRouter;
