import { put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./../ActionTypes/userActionType";
import { ToastNotifyError } from "./../../Components/Toast";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import {
  ADD_DELIVERY_PARTNER,
  ADD_OR_UPDATE_STORE_PICKUP_ADDRESS,
  deleteStorePickupAddressURL,
  fetchDeliveryPartnerInputConfigs,
  GET_DELIVERY_SETUP_INFO_API,
  GET_STORE_PICKUP_ADDRESSES,
  getServiceabilityByPincodes,
  SET_OR_REMOVE_DEFAULT_DELIVERY_PARTNER,
} from "../../Services/apiUrls";
import { parseToJson } from "../../Utils/common";
import { saveDeliverySetupInfo } from "../Actions";

function* getUserDeliverySetupInfo() {
  try {
    const deliveryInfo = yield apiCall({
      method: "GET",
      url: `${config.base_url}${GET_DELIVERY_SETUP_INFO_API}`,
    });
    if (deliveryInfo && deliveryInfo.data.status) {
      yield put(saveDeliverySetupInfo(parseToJson(deliveryInfo.data.data)));
    } else {
      ToastNotifyError(deliveryInfo.data.message);
    }
  } catch (err: any) {
    ToastNotifyError(err.data.message);
  }
}

function* setOrRemoveDefaultDeliveryPartner(actions) {
  try {
    const response = yield apiCall({
      method: "PATCH",
      url: `${config.base_url}${SET_OR_REMOVE_DEFAULT_DELIVERY_PARTNER}`,
      data: actions.data,
    });
    if (response && response.data.status) {
      actions.callback?.(true);
    } else {
      ToastNotifyError(response.data.message);
      actions.callback?.(false);
    }
  } catch (err: any) {
    ToastNotifyError(err.data.message);
    actions.callback?.(false);
  }
}

function* getDeliveryPartnerInputConfig(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${fetchDeliveryPartnerInputConfigs(actions.data)}`,
      parseToJson: true,
    });
    if (response && response.data.status) {
      actions.callback(response.data.data);
    } else {
      ToastNotifyError(response.data.message);
      actions.callback();
    }
  } catch (err: any) {
    ToastNotifyError(err.data.message);
    actions.callback();
  }
}

function* addDeliveryPartner(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${ADD_DELIVERY_PARTNER}`,
      data: actions.data,
    });
    if (response && response.data.status) {
      actions.callback?.(true);
    } else {
      ToastNotifyError(response.data.message);
      actions.callback?.(false);
    }
  } catch (err: any) {
    ToastNotifyError(err.data.message);
    actions.callback?.(false);
  }
}

function* getCityStateFromPincode(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/vm1/user/getCityStateByPinCode/${actions.data}`,
      parseToJson: true,
    });
    if (response && response.data.status) {
      actions.callback && actions.callback(response.data.data);
    }
  } catch (err) {
    ToastNotifyError(err.data.message);
  }
}

function* fetchStorePickupAddresses(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${GET_STORE_PICKUP_ADDRESSES}`,
      parseToJson: true,
    });

    if (response?.data?.status) {
      actions.callback?.(response.data?.data?.pickup_address_details || []);
    }
  } catch (err) {
    ToastNotifyError(err.data.message);
  }
}

function* addOrUpdateStorePickupAddress(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${ADD_OR_UPDATE_STORE_PICKUP_ADDRESS}`,
      data: actions?.data,
      parseToJson: true,
    });

    if (response?.data?.status) {
      actions.callback?.();
    }
  } catch (err) {
    ToastNotifyError(err.data.message);
  }
}

function* deleteStorePickupAddress(actions) {
  try {
    const response = yield apiCall({
      method: "PATCH",
      url: `${config.base_url}${deleteStorePickupAddressURL(actions?.data?.storeId, actions?.data?.addressId)}`,
      parseToJson: true,
    });
    if (response?.data?.status) {
      actions.callback?.();
    }
  } catch (error) {
    ToastNotifyError(error?.data?.message || "Unable to delete address");
  }
}

function* getServiceabilityByPickupAndDeliveryPincode(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${getServiceabilityByPincodes(actions?.data?.storeId, actions?.data?.sourcePincode, actions?.data?.destinationPincode)}`,
      parseToJson: true,
    });
    if (response?.data?.status) {
      actions.callback?.(response.data?.data || false);
    } else {
      actions?.callback?.(false);
    }
  } catch (error) {
    actions?.callback?.(false);
    ToastNotifyError(error?.data?.message || "Unable to check serviceability");
  }
}

export default function* root() {
  yield takeEvery(actionTypes.GET_DELIVERY_SETUP_INFO, getUserDeliverySetupInfo);
  yield takeEvery(actionTypes.FETCH_CITY_AND_STATE_FROM_PINCODE, getCityStateFromPincode);
  yield takeEvery(actionTypes.SET_OR_REMOVE_DEFAULT_DELIVERY_PARTNER, setOrRemoveDefaultDeliveryPartner);
  yield takeEvery(actionTypes.GET_DELIVERY_PARTNER_INPUT_CONFIG, getDeliveryPartnerInputConfig);
  yield takeEvery(actionTypes.ADD_DELIVERY_PARTNER, addDeliveryPartner);
  yield takeEvery(actionTypes.GET_STORE_PICKUP_ADDRESSES, fetchStorePickupAddresses);
  yield takeEvery(actionTypes.ADD_OR_UPDATE_STORE_PICKUP_ADDRESS, addOrUpdateStorePickupAddress);
  yield takeEvery(actionTypes.DELETE_STORE_PICKUP_ADDRESS, deleteStorePickupAddress);
  yield takeEvery(actionTypes.GET_SERVICEABILITY_BY_PINCODES, getServiceabilityByPickupAndDeliveryPincode);
}
