import * as paymentTypes from "../ActionTypes";

interface Action {
  type: string;
  data?: any;
  callback?: any;
}

export const savePrePaymentData = (data: any): Action => {
  return {
    type: paymentTypes.SAVE_PRE_PAYMENT_DATA,
    data,
  };
};

export const savePaymentInfo = (data: any): Action => {
  return {
    type: paymentTypes.SAVE_PAYMENT_INFORMATIONS,
    data,
  };
};

export const initPaymentForQR = (data: any, callback?: any): Action => {
  return {
    type: paymentTypes.INIT_PAYMENT_FOR_QR,
    data,
    callback,
  };
};

export const initPayment = (data: any, callback?: any): Action => {
  return {
    type: paymentTypes.INIT_PAYMENT,
    data,
    callback,
  };
};

export const savePostPaymentData = (data: any) => {
  return {
    type: paymentTypes.SAVE_POST_PAYMENT_DATA,
    data,
  };
};

export const pollPayment = (data: any, history: any, callback: any) => {
  return {
    type: paymentTypes.POLL_PAYMENT_STATUS,
    data,
    history,
    callback,
  };
};

export const verifyPayment = (data: any) => {
  return {
    type: paymentTypes.VERIFY_PAYMENT,
    data,
  };
};

export const savePaymentModesData = (data: any): Action => {
  return {
    type: paymentTypes.SAVE_PAYMENT_MODES_DATA,
    data,
  };
};

export const fetchPaymentModes = (data: any): Action => {
  return {
    type: paymentTypes.FETCH_PAYMENT_MODES,
    data,
  };
};

export const setPaymentOptions = (data: any, callback?: (status) => void): Action => {
  return {
    type: paymentTypes.SET_PAYMENT_OPTIONS,
    data,
    callback,
  };
};

export const paymentOptionsChange = (data: any): Action => {
  return {
    type: paymentTypes.PAYMENT_OPTIONS_CHANGE,
    data,
  };
};

export const paymentMethodChange = (data: any): Action => {
  return {
    type: paymentTypes.PAYMENT_METHOD_CHANGE,
    data,
  };
};

export const updatePaymentMethod = (data: any): Action => {
  return {
    type: paymentTypes.UPDATE_PAYMENT_METHOD,
    data,
  };
};

export const getPaymentModesByMerchantId = (data: any): Action => {
  return {
    type: paymentTypes.GET_PAYMENT_MODES_BY_MERCHANT_ID,
    data,
  };
};

export const setActivePaymentMethods = (data: any): Action => {
  return {
    type: paymentTypes.SET_ACTIVE_PAYMENT_METHODS,
    data,
  };
};

export const initLazyPayPayment = (data: any): Action => {
  return {
    type: paymentTypes.INIT_LAZY_PAY_PAYMENT,
    data,
  };
};

export const verifyLazyPayOtp = (data: any): Action => {
  return {
    type: paymentTypes.VERIFY_LAZY_PAY_OTP,
    data,
  };
};

export const toggleLazyPayAuth = (data: any): Action => {
  return {
    type: paymentTypes.TOGGLE_LAZYPAY_AUTH,
    data,
  };
};

/**
 * This action will be triggered only when all the payment has been done through credits
 * @param data
 */
export const completePaymentAction = (data: any): Action => {
  return {
    type: paymentTypes.COMPLETE_PAYMENT_ACTION,
    data,
  };
};

export const toggleMdrFlag = (data: any, callback?: any): Action => {
  return {
    type: paymentTypes.SET_MDR_FLAG,
    data,
    callback,
  };
};

export const toggleEmiFlag = (data: any, callback?: any): Action => {
  return {
    type: paymentTypes.TOGGLE_EMI_FLAG,
    data,
    callback,
  };
};

export const setPartialPaymentModeData = (data: any, callback?: any): Action => {
  return {
    type: paymentTypes.SET_PARTIAL_PAYMENT_DATA,
    data,
    callback,
  };
};
// EMI specific Actions

export const getEmiOptionsSpecificToCard = (data, callback) => {
  return {
    type: paymentTypes.GET_EMI_OPTIONS_FOR_CARD,
    data,
    callback,
  };
};

export const getAllEmiOptions = (data) => {
  return {
    type: paymentTypes.GET_ALL_EMI_OPTIONS,
    data,
  };
};

export const saveEmiOptionsSpecificToCard = (data) => {
  return {
    type: paymentTypes.SAVE_EMI_OPTION_FOR_CARD,
    data,
  };
};

export const saveAllEmiOptions = (data) => {
  return {
    type: paymentTypes.SAVE_ALL_EMI_OPTIONS,
    data,
  };
};

export const setEmiOptions = (data, callback) => {
  return {
    type: paymentTypes.SET_EMI_OPTION,
    data,
    callback,
  };
};

export const verifyGstForPurchase = (data, callback) => {
  return {
    type: paymentTypes.VERIFY_GST_FOR_PURCHASE,
    data,
    callback,
  };
};
