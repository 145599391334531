import axios, {
  AxiosInterceptorManager,
  AxiosPromise,
  AxiosRequestConfig as BaseAxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { ToastNotifyError } from "../Components/Toast";
import Config from "../Config";
import { LOGIN_ROUTE } from "../Constants/routesConstant";
import history from "../Utils/history";
import LocalStorageHelper from "../Utils/LocalStorageHelper";
import { LS_AUTH_TOKEN } from "../Constants/localStorageKeys";
import { QUERY_PARAM_AUTH, TEMP_TOKEN } from "../Utils/constants";
import { getAppOS, getUrlParams } from "../Utils/common";
import { goBackWebview } from "@/Utils/webview";

const defaultOptions = {
  baseURL: Config.base_url,
  headers: {
    "Content-Type": "application/json",
    // "app_os": 'web_console',
    // "app_version": "0.1.0"
  },
};

interface AxiosRequestConfig extends BaseAxiosRequestConfig {
  parseToJson?: boolean;
  signal?: AbortSignal;
}
export interface AxiosInstance {
  (config: AxiosRequestConfig): AxiosPromise;
  (url: string, config?: AxiosRequestConfig): AxiosPromise;
  defaults: AxiosRequestConfig;
  interceptors: {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse>;
  };
  getUri(config?: AxiosRequestConfig): string;
  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R>;
  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R>;
  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R>;
  head<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R>;
  options<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R>;
  post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
  put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
  patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
}

const api = axios.create(defaultOptions) as AxiosInstance;

/**
 * This URLSearchParams is used for authenticating 
 a user to dashy while coming from a Digital showroom landing page .
 */

const searchParams = new URLSearchParams(new URL(window.location.href).search);
const paramValue = searchParams.get(QUERY_PARAM_AUTH);
if (paramValue) {
  LocalStorageHelper.add(LS_AUTH_TOKEN, paramValue);
}

api.interceptors.request.use((item) => item);

api.interceptors.request.use((item: any) => {
  const sessid = sessionStorage.getItem("sess-id");
  item.headers.session_id = sessid ? `${sessid}` : "";
  if (item.url.includes("dotk")) {
    const { os, version } = getAppOS();
    item.headers.app_os = os;
    item.headers.app_version = version;
    /**
     * add new component item.headers.auth_token for authentication during webview opening
     *  LocalStorageHelper.get(TEMP_TOKEN) to verify the requestToCallbackAPI when user is not logged in.This will be deleted once we get suucess response from requestToCallback api
     */
    const { token } = getUrlParams(["token"]);
    const authToken =
      item.headers.auth_token || LocalStorageHelper.get(LS_AUTH_TOKEN) || token || LocalStorageHelper.get(TEMP_TOKEN);
    item.headers.auth_token = authToken;
  }
  return item;
});

api.interceptors.response.use(
  (resp) => {
    if ((resp?.config as AxiosRequestConfig)?.parseToJson && resp?.data?.data) {
      resp.data.data = JSON.parse(resp?.data?.data);
    }
    return resp;
  },
  (err) => {
    if (err?.response?.status === 401) {
      if (window?.ReactNativeWebView) {
        goBackWebview();
      } else {
        history.push(LOGIN_ROUTE);
      }
      ToastNotifyError("Logged out due to system maintenance activity");
      return;
    }
    throw err.response;
  },
);

export default api;
