export const EVENTS_GOOGLE_SHOPPING_V2 = {
  TILE_CLICKED: "Google Shopping Tile Clicked",
  SETUP_LANDING: "Setup Landing",
  STEP_1: "Google Shopping Setup Step 1",
  STEP_2: "Google Shopping Setup Step 2",
  OTP_SUBMITTED: "OTP Submitted",
  DASHBOARD: "Google Shopping Landing",
  NOT_SUBMITTED_BANNER: "Google Shopping NS Banner",
  NOT_SUBMITTED_LANDING: "Google Shopping NS Landing",
  AUTO_GENERATE_AI: "Google Shopping NS AI",
  SAVE_ITEM_DETAILS: "Google Shopping Submitted",
};

export const EVENTS_BRAND_WEBSITE = {
  EXPLORE_BRAND_WEBSITE: "Explore_Premium_websites",
  BUSINESS_CATEGORIES_PAGE: {
    EXPLORE_CATEGORY: "Explore_businessCategory_premiumWebsite",
    EDIT_THEME: "Premium_businessCategory_edit_theme",
    THEME_PREVIEW: "Premium_businessCategory_preview",
    REQUEST_CALLBACK: "Premium_businessCategory_RequestToCallback",
  },
  CATEGORY_THEMES_PAGE: {
    PREVIEW_THEME: "Premium_Website_Explore_Preview",
    EDIT_THEME: "Premium_Website_Explore_edit",
    APPLY_THEME: "Premium_Website_Explore_Apply",
    BUY_THEME: "Premium_Website_Explore_buy",
    REQUEST_CALLBACK: "Premium_Website_Explore_RequestTOcallback",
  },
  THEME_PREVIEW_PAGE: {
    EDIT_THEME: "Premium_Website_preview_edit",
    APPLY_THEME: "Premium_Website_preview_apply",
    BUY_THEME: "Premium_Website_preview_buynow",
    REQUEST_CALLBACK: "Premium_Website_preview_RequestTOcallback",
    VIEW_DEMO: "Premium_Website_preview_ViewDemoWebsite",
  },
};

export const EVENTS_PREMIUM_PAGE = {
  HOME_PAGE: {
    VIEW_ACTIVE_SUBSCRIPTIONS: "manage_premium_page",
    DOMAIN_PURCHASE: "domain_purchase_premium_page",
    DOMAIN_EXPLORE: "domain_premium_page",
    EXPLORE_BRAND_WEBSITE: "brand_website_premium_page",
    EXPLORE_THEME: "theme_premium_page",
    ECOMM_CALLBACK: "ecomm_callback_premium_page",
    ECOMM_EXPLORE: "ecomm_explore_premium_page",
    EXPLORE_ADDITIONAL_PAGE: "additional_page_premium_page",
    GET_WORKSPACE: "workspace_premium_page",
    GET_WABA: "waba_premium_page",
    GET_GST: "gst_premium_page",
  },
  ACTIVE_SUBSCRIPTION_PAGE: {
    CTA_CLICKED: "product_clicked_manage_page",
  },
};

export const DLP_WEBSITE = {
  DOMAIN_SEARCHED: "Domain_Landing_Page_Search",
  CONTACT_CALLBACK: "Domain_Landing_Page_Callback",
  OTP_VERIFIED: "DLP_OTPVerified",
  OTP_VERIFIED_HEADER: "DLP_OTPVerified_Header",
  OTP_VERIFIED_SIGNIN: "DLP_OTPVerified_Signin",
};

export const EVENTS_THEME_UPSELL = {
  THEME_ONLY: "theme_upsell_payment_initiated",
  THEME_PRO: "theme_plus_upsell_payment_initiated",
};

export const EVENTS_THEME = {
  APPLY: "Theme_Apply",
};

export const EVENTS_PROFILE = {
  REGISTER_NUMBER_CHANGE: "register_number_change",
};

export const PRODUCT_PERSONALISATION_EVENTS = {
  ADD_PERSONALISATION: "add_personalisation_item",
  UNLOCK_PERSONALISATION: "add_personalisation_lock",
  DONE_CONFIGURATION: "add_personalisation_done",
  DOWNLOAD_IMAGES: "add_personalisation_download",
  COPY_TEXT: "add_personalisation_copy",
  EDIT_CONFIGURATION: "add_personalisation_manage",
};

export const DELIVERY_PARTNER_CT_EVENTS = {
  SIGNUP: "delivery_partner_sign_up",
  SET_AS_DEFAULT: "delivery_partner_set_as_default",
  REMOVE_AS_DEFAULT: "delivery_partner_remove_as_default",
  LINK_ACCOUNT: "delivery_partner_link_account",
  VERIFY_KYC: "delivery_partner_verify_kyc",
  RETRY_KYC: "delivery_partner_retry_kyc",
  ADD_EMAIL: "delivery_partner_add_email",
  ADD_ADDRESS: "delivery_partner_add_address",
  SHIP_USING_PARTNER: "delivery_partner_ship_using_partner",
  PAYMENT_AND_PICKUP_SELECTION_DONE: "delivery_partner_payment_and_pickup_selection_done",
  PACKAGE_DIMENSION_ADDITION_DONE: "delivery_partner_package_dimesion_addition_done",
  PARTNER_SELECTION_DONE: "delivery_partner_partner_selection_done",
  TABLE_FILTER_CLICK: "delivery_partner_table_filter_click",
  TABLE_FILTER_APPLY_CLICK: "delivery_partner_table_filter_apply_click",
  TABLE_FILTER_CLEAR_CLICK: "delivery_partner_table_filter_clear_click",
  TABLE_SORT_CLICK: "delivery_partner_table_sort_click",
  TABLE_SORT_FASTEST_CLICK: "delivery_partner_table_sort_fastest_click",
  TABLE_SORT_CHEAPEST_CLICK: "delivery_partner_table_sort_cheapest_click",
  TABLE_TAB_ALL_CLICK: "delivery_partner_table_tab_all_click",
  TABLE_TAB_SURFACE_CLICK: "delivery_partner_table_tab_surface_click",
  TABLE_TAB_AIR_CLICK: "delivery_partner_table_tab_air_click",
  TABLE_SEARCH_CLICK: "delivery_partner_table_search_click",
  SHIPMENT_STATUS_PARTNER_DASHBOARD_CLICK: "delivery_partner_shipment_status_partner_dashboard_click",
  SHIPMENT_STATUS_SCHEDULE_PICKUP_CLICK: "delivery_partner_shipment_status_schedule_pickup_click",
  SHIPMENT_STATUS_TRACK_ORDER_CLICK: "delivery_partner_shipment_status_track_order_click",
} as const;
